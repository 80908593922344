import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import * as Yup from "yup";
import Card from "../../../components/Card";
import ModalConfirm from "../../../components/ModalConfirm";
import ModalMsg from "../../../components/ModalMsg";
import { LoaderContext } from "../../../context/LoaderContext";

function NotificaInvia() {

    const [loadingData, setLoadingData] = useState(true);
    const [showConfirmSend, setShowConfirm] = useState(false);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);

    const [optionsInstallazioni, setOptionsInstallazioni] = useState([]);
    const [destinatari, setDestinatari] = useState([]);

    const loader = useRef(useContext(LoaderContext));

    const history = useHistory();
    const formikRef = useRef();

    useEffect(() => {
        loader.current.startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/installazione/").then((response) => {
            const options = response?.data?.data.map(i => ({
                value: i.id_installazione,
                label: i.descrizione
            }));
            setOptionsInstallazioni(options);
            setLoadingData(false);
            loader.current.endChiamata();
        });
    }, []);

    useEffect(() => {
        formikRef.current.setFieldValue('destinatari', destinatari);
    }, [destinatari]);

    const closeModalSend = () => setShowConfirm(false);
    const closeModal = () => {
        setShow(false);
        history.push('/notifica-storico');
    }

    const sendNotification = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit()
        }
        closeModalSend();
    }

    const SignupSchema = Yup.object().shape({
        destinatari: Yup.array()
            .test('required', 'Questo campo è obbligatorio', (value) => value?.length > 0),
        oggetto: Yup.string()
            .max(2000, 'Valore troppo lungo')
            .required('Questo campo è obbligatorio'),
        testo: Yup.string()
            .required('Questo campo è obbligatorio'),
    });

    return (
        <div>
            <ModalConfirm
                show={showConfirmSend}
                handleClose={closeModalSend}
                handleOk={sendNotification}
                testo={"Confermi di voler inviare la notifica?"}/>
            <ModalMsg
                show={show}
                handleClose={closeModal}
                testo={modalMsg}/>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <Card
                        icon={'fas fa-envelope'}
                        title={'Invia notifica'}
                        getBottoniSx={() =>
                            <Link to={"/"} className="btn btn-card">
                                <i className="fas fa-angle-left"/>
                            </Link>
                        }
                        getBottoniDx={() =>
                            <button type="button" id="salva" name="salva"
                                    className="btn btn-card"
                                    onClick={() => {
                                        setShowConfirm(true);
                                    }}>
                                <i className="fas fa-save"/> <span className={'cmms-display-mobile'}>Salva</span>
                            </button>
                        }>
                        <Formik
                            initialValues={{
                                destinatari: '',
                                oggetto: '',
                                testo: ''
                            }}
                            innerRef={formikRef}
                            validationSchema={SignupSchema}
                            onSubmit={values => {
                                loader.current.startChiamata();
                                    axios
                                        .post(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/notifica/",
                                            values)
                                        .then((response) => {
                                            if (response.data.result === 0) {
                                                loader.current.endChiamata();
                                                history.push('/notifica-storico');
                                            } else {
                                                setModalMsg(response.data.description);
                                                setShow(true);
                                                loader.current.endChiamata();
                                            }
                                        });
                            }}
                        >
                            {({errors, touched}) => (
                                <Form className="row g-3">
                                    <div className="col-md-12">
                                        <label htmlFor="destinatari" className="form-label">Destinatari*:</label>
                                        <Field
                                            component={Select}
                                            value={optionsInstallazioni.filter(o => destinatari.includes(o.value))}
                                            name="destinatari"
                                            options={optionsInstallazioni}
                                            onChange={(opts) => setDestinatari(opts.map(o => parseInt(o.value)))}
                                            isMulti
                                        />
                                        {errors.destinatari && touched.destinatari ? (
                                            <div className="cmms-div-err-form">{errors.destinatari}</div>
                                        ) : null}
                                        <Button onClick={() => setDestinatari(optionsInstallazioni.map(o => o.value))}>Seleziona tutti</Button>
                                        <Button variant="secondary" onClick={() => setDestinatari([])}>Deseleziona tutti</Button>
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="oggetto" className="form-label">Oggetto*:</label>
                                        <Field
                                            name="oggetto"
                                            className="form-control"
                                        />
                                        {errors.oggetto && touched.oggetto ? (
                                            <div className="cmms-div-err-form">{errors.oggetto}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="testo" className="form-label">Testo*:</label>
                                        <Field
                                            name="testo"
                                            className="form-control"
                                            as="textarea"
                                            rows={15}
                                        />
                                        {errors.testo && touched.testo ? (
                                            <div className="cmms-div-err-form">{errors.testo}</div>
                                        ) : null}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default NotificaInvia;
