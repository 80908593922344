import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { Link, useHistory, useParams } from 'react-router-dom'
import Card from "../../../components/Card";
import { Field, Form, Formik } from "formik"
import ModalMsg from "../../../components/ModalMsg";
import * as Yup from "yup";
import { LoaderContext } from "../../../context/LoaderContext";
import { Tooltip as ReactTooltip } from "react-tooltip";
import NsDatePicker from "../../../components/NsDatePicker";
import { convertiDateInDataPerDb } from "../../../utility/common";

function ModificaInstallazionePortal({ installazione, datiLicenza }) {

    const iconaDefault = 'fas fa-cogs';

    const [loadingData, setLoadingData] = useState(true);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [endpoint, setEndpoint] = useState(false);

    const { startChiamata, endChiamata } = useContext(LoaderContext);

    const history = useHistory();
    const formikRef = useRef();


    useEffect(() => {
        if (datiLicenza && formikRef.current) {
            formikRef.current.setFieldValue("id", datiLicenza.id || '')
            formikRef.current.setFieldValue("id_installazione", datiLicenza.id_installazione || '')
            formikRef.current.setFieldValue("descrizione", datiLicenza.descrizione || '')

            formikRef.current.setFieldValue("partner", datiLicenza.partner || '')
            formikRef.current.setFieldValue("versione", datiLicenza.versione || '')

            formikRef.current.setFieldValue("endpoint", datiLicenza.endpoint || '')         /*===???===*/
            formikRef.current.setFieldValue("num_superad", datiLicenza.num_superad || '')
            formikRef.current.setFieldValue("num_admin", datiLicenza.num_admin || '')
            formikRef.current.setFieldValue("num_tecnici", datiLicenza.num_tecnici || '')
            formikRef.current.setFieldValue("num_guest", datiLicenza.num_guest || '')
            formikRef.current.setFieldValue("max_space_disk", datiLicenza.max_space_disk || '')
            formikRef.current.setFieldValue("data_scadenza", datiLicenza.data_scadenza ? new Date(datiLicenza.data_scadenza) : null)
            formikRef.current.setFieldValue("data_scadenza_mtbf", datiLicenza.data_scadenza_mtbf ? new Date(datiLicenza.data_scadenza_mtbf) : null)
        }
    }, [installazione, datiLicenza]);


    const SignupSchema = Yup.object().shape({  
        num_superad: Yup.string()
            .required(''),
        num_admin: Yup.string()
            .required(''),
        num_tecnici: Yup.string()
            .required(''),
        num_guest: Yup.string()
            .required(''),
        max_space_disk: Yup.string()
            .required(''),
        data_scadenza: Yup.string()
            .required(''),
    });

    return (
        <div>
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg} />

            <Card
                icon={'fas fa-note-sticky'}
                title={'Modifica Installazione'}
                getBottoniSx={() =>
                    <Link to={"/dashboard"} className="btn btn-card">
                        <i className="fas fa-angle-left" />
                    </Link>
                }
                getBottoniDx={() =>
                    <button type="button" id="salva" name="salva"
                        className="btn btn-card"
                        onClick={() => {
                            if (formikRef.current) {
                                formikRef.current.handleSubmit()
                            }
                        }}>
                        <i className="fas fa-save" /> <span className={'cmms-display-mobile'}></span>
                    </button>
                }>
                <Formik
                    initialValues={{
                        id: '',
                        id_installazione: '',
                        descrizione: '',
                        partner: '',
                        versione: '',
                        endpoint: '',
                        num_superad: '',
                        num_admin: '',
                        num_tecnici: '',
                        num_guest: '',
                        max_space_disk: '',
                        data_scadenza: '',
                        data_scadenza_mtbf: ''
                    }}
                    innerRef={formikRef}
                    validationSchema={SignupSchema}
                    onSubmit={values => {
                        let newValues = values;
                        newValues["data_scadenza"] = convertiDateInDataPerDb(newValues["data_scadenza"]);
                        newValues["data_scadenza_mtbf"] = convertiDateInDataPerDb(newValues["data_scadenza_mtbf"]);

                        startChiamata()
                        axios
                            .put(installazione.endpoint + "api/cmms/info/licenza/",
                                newValues,
                                {
                                    headers: {
                                        "jwt" : localStorage.getItem('cmms-login-inst'),
                                    }
                                }
                            )
                            .then((response) => {
                                if (response.data.result === 0) {
                                    history.goBack();
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                }
                                endChiamata()
                            });
                    }}
                >
                    {({ errors, touched, values }) => (
                        <>

                            <Form id="mod-form" className="py-md-5">

                                <div className="container-fluid">
                                    <div className="row mt-md-5">
                                        <div className="col-12 col-md-3">
                                            <label htmlFor="num_superad" className="form-label" data-tooltip-id="obbligatorio">Super amministratore*:</label>
                                            <Field name="num_superad" type="number" className="form-control"
                                            />
                                            {errors.num_superad && touched.num_superad ? (
                                                <div className="cmms-div-err-form">{errors.num_superad}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <label htmlFor="num_admin" className="form-label" data-tooltip-id="obbligatorio">Amministratore*:</label>
                                            <Field name="num_admin" type="number" className="form-control" />
                                            {errors.num_admin && touched.num_admin ? (
                                                <div className="cmms-div-err-form">{errors.num_admin}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <label htmlFor="num_tecnici" className="form-label" data-tooltip-id="obbligatorio">Tecnico*:</label>
                                            <Field name="num_tecnici" type="number" className="form-control" />
                                            {errors.num_tecnici && touched.num_tecnici ? (
                                                <div className="cmms-div-err-form">{errors.num_tecnici}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <label htmlFor="num_guest" className="form-label" data-tooltip-id="obbligatorio">Segnalatore*:</label>
                                            <Field name="num_guest" type="number" className="form-control" />
                                            {errors.num_guest && touched.num_guest ? (
                                                <div className="cmms-div-err-form">{errors.num_guest}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="row mt-md-5">
                                        <div className="col-12 col-md-4">
                                            <label htmlFor="max_space_disk" className="form-label" data-tooltip-id="obbligatorio">Spazio disco*:</label>
                                            <Field name="max_space_disk" type="number" className="form-control" />
                                            {errors.max_space_disk && touched.max_space_disk ? (
                                                <div className="cmms-div-err-form">{errors.max_space_disk}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <label htmlFor="data_scadenza" className="form-label">Data di scadenza*:</label>
                                            <NsDatePicker nome={"data_scadenza"}
                                                dataSelezionata={values["data_scadenza"]}
                                                setSelectedDate={(data, e) => {
                                                    formikRef.current.setFieldValue("data_scadenza", data);
                                                }}
                                                anniStart={new Date().getFullYear()}
                                                anniEnd={30}
                                            />
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <label htmlFor="data_scadenza_mtbf" className="form-label">Data di scadenza MTBF-MTTR:</label>
                                            <NsDatePicker nome={"data_scadenza_mtbf"}
                                                dataSelezionata={values["data_scadenza_mtbf"]}
                                                setSelectedDate={(data, e) => {
                                                    formikRef.current.setFieldValue("data_scadenza_mtbf", data);
                                                }}
                                                anniStart={new Date().getFullYear()}
                                                anniEnd={30}
                                            />
                                        </div>
                                    </div>
                                    <ReactTooltip
                                        id="obbligatorio"
                                        place="top"
                                        content="Questo campo è obbligatorio"
                                        style={{ backgroundColor: "rgba(53,92,151,0.8)", color: "#fff", width: "200px", fontSize: "16px" }}
                                        className={"text-center"}
                                    />
                                </div>
                            </Form>

                        </>
                    )}
                </Formik>
            </Card>

        </div>
    );
}

export default ModificaInstallazionePortal;