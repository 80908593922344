import axios from "axios";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Card from "../../../components/Card";
import CardContainer from "../../../components/CardContainer";
import DataTableCmms from "../../../components/tabelle/NsDataTable";
import { LoaderContext } from "../../../context/LoaderContext";
import { dateToMilliseconds, getTimestampFormattato } from "../../../utility/common";

function NotificaStorico() {

    const [loadingData, setLoadingData] = useState(true);

    const [data, setData] = useState([]);

    const loader = useRef(useContext(LoaderContext));

    useEffect(() => {
        loader.current.startChiamata();
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/cmms/notifica/").then((response) => {
            setData(response.data.data);
            setLoadingData(false);
            loader.current.endChiamata();
        });
    }, []);

    const funzioneFiltro = (item, filtro) => {
        return (item.installazione && item.installazione.toLowerCase().includes(filtro.toLowerCase()))
            || (item.destinatari && item.destinatari.toLowerCase().includes(filtro.toLowerCase()))
            || (item.oggetto && item.oggetto.toLowerCase().includes(filtro.toLowerCase()))
            || (item.testo && item.testo.toLowerCase().includes(filtro.toLowerCase()))
    }

    const columns = [
        {
            name: 'Invio riuscito',
            selector: row => row.is_ok
                ? <span className="cmms-span--verde"><i className="fas fa-check"/></span>
                : <span className="cmms-span--rosso"><i className="fas fa-xmark"/></span>,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.is_ok < b.is_ok) {
                    return -1;
                }

                if (a.is_ok > b.is_ok) {
                    return 1;
                }

                return 0;
            },
        },{
            name: 'Data',
            selector: row => row.data_ins ? getTimestampFormattato(row.data_ins) : '',
            sortable: true,
            sortFunction: (a, b) => {
                let dataA = dateToMilliseconds(a.data_ins);
                let dataB = dateToMilliseconds(b.data_ins);

                if (dataA < dataB) {
                    return -1;
                }

                if (dataA > dataB) {
                    return 1;
                }

                return 0;
            }
        },
        {
            name: 'Installazione',
            selector: row => row.installazione,
            sortable: true,
        },
        {
            name: 'Destinatari',
            selector: row => row.destinatari?.length > 200 ? row.destinatari?.substr(0, 200) + '...' : row.destinatari,
            sortable: true,
        },
        {
            name: 'Oggetto',
            selector: row => row.oggetto?.length > 100 ? row.oggetto?.substr(0, 100) + '...' : row.oggetto,
            sortable: true,
        },
        {
            name: 'Testo',
            selector: row => row.testo?.replaceAll('</br>','\n').length > 200 ? row.testo?.replaceAll('</br>','\n').substr(0, 200) + '...' : row.testo?.replaceAll('</br>','\n'),
            sortable: true,
        },
        {
            name: '',
            selector: row => <Fragment>
                <Link to={"/notifica-info/" + row.id}
                      className="btn btn-dark cmms-btn-table"
                      data-toggle="tooltip" title="Visualizza">
                    <i className="fas fa-eye"/> <span className={'cmms-display-mobile'}>Visualizza</span>
                </Link>
            </Fragment>,
            sortable: false,
            right: true,
        }
    ];

    return (
        <CardContainer>
            <Card
                icon={'fas fa-clock-rotate-left'}
                title={'Storico notifiche'}>
                {
                    loadingData ? (
                        <p>Caricamento in corso...</p>
                    ) : (
                        <Fragment>
                            <DataTableCmms
                                data={data}
                                columns={columns}
                                funzioneFiltro={funzioneFiltro}
                            >
                            </DataTableCmms>
                        </Fragment>
                    )
                }
            </Card>
        </CardContainer>
    );
}

export default NotificaStorico;
