import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Card from "../../../components/Card";
import Translate from "../../../components/Translate";
import { LoaderContext } from "../../../context/LoaderContext";

function NotificaInfo() {

    const {id} = useParams();

    const [loadingData, setLoadingData] = useState(true);

    const [notifica, setNotifica] = useState([]);
    const loader = useRef(useContext(LoaderContext));

    useEffect(() => {
        loader.current.startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + `/api/cmms/notifica/${id}`).then((response) => {

            setNotifica(response?.data?.data);
            setLoadingData(false);
            loader.current.endChiamata();
        });
    }, [id]);

    return (
        <div>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <Card
                        icon={'fas fa-envelope'}
                        title={'Notifica'}
                        getBottoniSx={() =>
                            <Link to={"/notifica-storico"} className="btn btn-card">
                                <i className="fas fa-angle-left"/>
                            </Link>
                        }>
                        <div className="row">
                            <div className={"col-md-1 col-4"}>
                                <Translate>Invio riuscito:</Translate>
                            </div>
                            <div className={"col-md-3 col-7"}>
                                <strong>{
                                    notifica.is_ok
                                        ? <span className="cmms-span--verde"><i className="fas fa-check"/></span>
                                        : <span className="cmms-span--rosso"><i className="fas fa-xmark"/></span>
                                }</strong>
                            </div>
                        </div>
                        <div className="row">
                            <div className={"col-md-1 col-4"}>
                                <Translate>Installazione:</Translate>
                            </div>
                            <div className={"col-md-3 col-7"}>
                                <strong>{notifica.installazione}</strong>
                            </div>
                            <div className={"col-md-1 col-4"}>
                                <Translate>Destinatari:</Translate>
                            </div>
                            <div className={"col-md-7 col-7"}>
                                <strong>{notifica.destinatari}</strong>
                            </div>
                            <div className={"col-md-1 col-4"}>
                                <Translate>Oggetto:</Translate>
                            </div>
                            <div className={"col-md-3 col-7"}>
                                <strong>{notifica.oggetto}</strong>
                            </div>
                        </div>
                        <div className="row">
                            <div className={"col-md-1 col-4"}>
                                <Translate>Testo:</Translate>
                            </div>
                            <div className={"col-md-11 col-7 line-break"}>
                                <strong>{notifica.testo?.replaceAll('</br>','\n')}</strong>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default NotificaInfo;
